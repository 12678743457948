<template>
  <div>
    <el-container>
      <el-header
        :style="{
          'border-bottom':
            $root.config.theme === 'dark' ? '1px solid #18212c' : '1px solid #eaecef',
        }"
      >
        <div class="user-container">
          <el-image :src="logoUrl" class="user-container-head"></el-image>
        </div>
      </el-header>
      <el-main>
        <el-tabs tab-position="left" class="tabs">
          <el-tab-pane label="🍎你好，模型树"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="什么是模型树？"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p id="isPasted">模型树是由兰陵王发明的一种学习方法。</p>
              <p>此学习方法融合了：</p>
              <p>
                记忆的底层原理，比如组块思维、新旧知识的联系、间隔重复、检索练习、联想记忆等等；
              </p>
              <p>
                思考的底层原理，比如查理·芒格的思维模型，麦肯锡的金字塔原理，马斯克的第一原理思维，以及兰陵王自己所感悟到的存量思维；
              </p>
              <p>知识本身的特性，比如显隐特性、收敛特性、关联特性等等。</p>
              <p>
                它弥补了思维导图、记忆宫殿、卡片笔记、双链笔记、知识管理、检索练习等诸多学习方法的缺陷，或者说它本来就是这些学习方法的集大成者；
              </p>
              <p>
                但又不是简单的融合，而是回到学习的第一原理，再反推回来，得到的一个最科学的学习方法。
              </p>
              <p>
                它既能解决人们的学完就完的问题，还能同时提升人们的思考能力、理解能力等等，更神奇的是，它还能让枯燥的学习变得像打游戏一样好玩，解决了人们的学习动力问题。
              </p>
              <p>
                除此之外，模型树也是一套科学的知识管理方法，它能让你在学习的同时，构建出自己的知识体系。
              </p>
              <p>
                目前已经有很多人因此而受益，希望将来有更多人因此而受益。
              </p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="模型树怎么用">
            <el-scrollbar style="height: calc(100vh - 100px)">
              <p id="isPasted">模型树课程对应操作步骤:</p>
              <p>1五马分尸</p>
              <p>即把巨人的知识“切碎”。</p>
              <p>2 提炼模型</p>
              <p>提炼模型又细分为两个步骤：首先，淘金；然后，提炼。</p>
              <p>淘金：即识别哪些是有价值的知识点。</p>
              <p>提炼：把有价值的知识点提炼为模型。</p>
              <p>3 形成体系</p>
              <p>把提炼好的模型，放进云笔记，形成一个树状的知识体系。</p>
              <p>4 存量知识</p>
              <p>
                这一步主要就是用到兰陵王自己发明的存量思维，以及主题阅读等优秀的学习方法。
              </p>
              <p>5 回归本质</p>
              <p>
                这一步主要就是利用了知识等收敛特性，以及第一原理思维，不断收敛思维模型，让它回归到第一原理。
              </p>
              <p>6 修剪模型树</p>
              <p>
                前面5步是种植模型的核心步骤，在学习的过程，还需要不断修剪你的模型树，让知识回归到它原本的结构。
              </p>
              <p>--</p>
              <p>详细学习方式可参考 模型树入门课程和高阶课程</p>
              <p>模型树 入门课程：点击报名</p>
              <p>
                适合用户：不了解模型树的用户，希望不断提升自己学习能力的用户。
              </p>
              <p>模型树 高阶课程：点击报名</p>
              <p>
                适合用户：对模型树有一定了解的用户，可以系统学习；我们还会定期办理模型树训练营。
              </p>
              <p>模型树云笔记怎么用？:</p>
              <p>模型树云笔记是根据模型树课程开发的云笔记软件，</p>
              <p>
                帮你快速对知识进行提炼模型，快速形成体系，输入存量知识，回归事物本质
              </p>
              <p>通过不断修剪模型树，最终将知识内化。</p>
              <p>模型树主要功能如下：</p>
              <p>1.支持快速新建树和新建模型，支持存量知识快速填充</p>
              <p>
                2.支持全局思维导图查看，支持思维导图模式下一键调起文档，进行快速编辑
              </p>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="模型树·免费课"
            ><el-scrollbar style="height: calc(100vh - 100px)">
              <p id="isPasted">
                我们几乎每天都在学习，却很少有人学过如何学习。
              </p>
              <p>这不得不让人感到震撼和遗憾。</p>
              <p>
                假设我们能活到80岁，20岁接触学习方法，假设学习学习方法需要20个小时，
              </p>
              <p>
                再假设学习方法能帮我们把学习效率提升50%（实际可能要远高于这个比例），
              </p>
              <p>那么在接下来的60年中，我们将享受到256320小时的增值。</p>
              <p>
                用20&nbsp;个小时换来256320&nbsp;小时的增值，恐怕世界上没有比这更划算的投资了。
              </p>
              <p>然而这么简单的道理，却很少有人懂得。</p>
              <p>
                学习方法是个超级杠杆&nbsp;，你只需要花20个小时就能获得256320小时的增值。
              </p>
              <p>
                这个秘密告诉你，不管你学不学知识树（现更名为 模型树
                ），我都希望你可以花点时间学学学习方法。
              </p>
              <p>微信读书上就有很多关于学习方法的书，</p>
              <p>
                比如《刻意练习》、《学习之道》、《认知天性》、《如何高效学习》、《好好学习》等等。
              </p>
              <p>花点时间学学，相信我，没有坏处的。</p>
              <p>
                古人讲「磨刀不误砍柴工」，学习学习方法就是在磨刀，学习其他知识就是在砍柴。
              </p>
              <p>
                虽然砍柴才是目的，但是老师傅们都懂得一个道理——磨刀并不会耽误砍柴&nbsp;，相反还会加快整体的工作进度。
              </p>
              <p>
                可悲的是，大多数人一辈子都没有磨过刀；更可悲的是，很多人是连刀都没有。
              </p>
              <p>但我希望你们砍柴的时候有刀，而且是很锋利的刀。</p>
              <p>
                正如查理·芒格所说的「人类只有发明了发明的方法之后，才能快速发展；我们也只有学习了学习的方法之后，才能快速进步」&nbsp;。
              </p>
              <p>看过《倚天屠龙记》吗？张无忌最厉害的是什么能力？</p>
              <p>
                学习能力！还记得张三丰把太极拳传给张无忌的时候，说的什么话吗？
              </p>
              <p>无忌，你有九阳神功护体，学什么都快。</p>
              <p>
                现在九阳神功（学习方法）就真实地摆在你面前，你打算错过它吗？
              </p>
              <p
                style="
                  margin-block: 0px 0.8em;
                  line-height: 1.5;
                  text-align: justify;
                  font-size: 15px;
                  color: rgb(13, 20, 30);
                  font-family: 'PingFang SC';
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: 1px;
                  orphans: 2;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <img
                  data-fr-image-pasted="true"
                  src="http://wechatapppro-1252524126.file.myqcloud.com/image/ueditor/60335800_1624542580.cn/mmbiz_png/uicyqiauwr3iwwlczs798w3h45zn8upibiapuibvjmdznlicwrwlyrgeibgcdschrc5gibyddthyqxxiafyufiblmiaqfbra/640"
                  alt=""
                  style="
                    position: relative;
                    max-width: 100%;
                    cursor: pointer;
                    padding: 0px 1px;
                    user-select: none;
                  "
                />
              </p>
              <p>什么是最强学习法</p>
              <p>——模型树</p>
              <p>那么我们应该学习什么样的学习方法呢？</p>
              <p>记忆宫殿？很强，但还不够。</p>
              <p>检索练习？很强，但还不够。</p>
              <p>刻意练习？很强，但还不够。</p>
              <p>费曼学习？很强，但还不够。</p>
              <p>结构思维？很强，但还不够。</p>
              <p>组块思维？很强，但还不够。</p>
              <p>思维模型？很强，但还不够。</p>
              <p>第一原理？很强，但还不够。</p>
              <p>知识管理？必须，但还不够。</p>
              <p>……</p>
              <p>合在一起？恭喜你，答对了。</p>
              <p>
                我通过不断的研究、思考、实践，最终把它们融为了一炉，形成了一个强大又简单的学习方法——模型树。
              </p>
              <p>
                它集合了最强大的记忆方法&nbsp;，比如新旧联系、意义编码、检索练习、间隔重复、刻意练习、组块思维等等；
              </p>
              <p>
                也集合了最强大的思考方法&nbsp;，比如麦肯锡的结构思维、查理·芒格的思维模型、埃隆·马斯克的第一原理、兰陵王的存量思维等等；
              </p>
              <p>最终加上外接大脑&nbsp;这个神器，所有东西融为一炉，封神。</p>
              <p>
                这样我们就能在记忆的同时，还能保证颅内着火式的思考深度；在思考的同时，还能保证过目不忘式的记忆效果。
              </p>
              <p>这就是这个时代——终身学习的时代，理性乐观进取者的标配武器。</p>
              <p>
                你也许会质疑，它真的有这么强大吗？它真的对人的影响有这么大吗？
              </p>
              <p>
                我可以摸着我的心告诉你，如果你真的掌握了它的精髓，你就会每天用它来学习，从此以后，你将再也离不开它，它将影响你的一生。
              </p>
              <p>
                我现在就是，我学习任何东西都要用模型树，如果用不了模型树，我甚至会选择暂时不学习。
              </p>
              <p>
                我深深地感受到了它的强大，也享受着它带给我的好处，所以才想要让更多人能够用起来，让更多人也能享受到它的好处。
              </p>
              <p>不管你信不信，这就是我们目前的使命——为中国培养10000+巨人。</p>
              <p
                style="
                  margin-block: 0px 0.8em;
                  line-height: 1.5;
                  text-align: justify;
                  font-size: 15px;
                  color: rgb(13, 20, 30);
                  font-family: 'PingFang SC';
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: 1px;
                  orphans: 2;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <img
                  data-fr-image-pasted="true"
                  src="http://wechatapppro-1252524126.file.myqcloud.com/image/ueditor/5615500_1624543533.cn/mmbiz_png/uicyqiauwr3iwwiaxa6j07tn6xbnu7pjp6vmq9v6iaecmrb7cuv2plzsz9dtdvpuhhe7ricu65ztlpvzvea19pj0sdg/640"
                  alt=""
                  style="
                    position: relative;
                    max-width: 100%;
                    cursor: pointer;
                    padding: 0px 1px;
                    user-select: none;
                  "
                />
              </p>
              <p>学习课程你将获得什么</p>
              <p>一共7节课。</p>
              <p>一、具体的课表</p>
              <p>第1课 二元学习法</p>
              <p>第2课 巨人思维</p>
              <p>第3课 模型树的种植</p>
              <p>第4课 模型树修剪·主体篇</p>
              <p>第5课 模型树修剪·叶子篇</p>
              <p>第6课 第一性原理</p>
              <p>第7课 费曼学习法</p>
              <p>二、你将从课程中获得什么？</p>
              <p>1、科学的知识体系搭建方法</p>
              <p>2、快速学习任何知识的能力</p>
              <p>3、提升记忆能力，告别学完就忘</p>
              <p>4、学会深度思考，看穿事物本质</p>
              <p>课程咨询及其他</p>
              <p>课程报名地址：点击报名</p>
              <p>如果您想成为课程推广大使，或和其他读者、学员交流讨论。</p>
              <p>请加负责人微信：siweimoxing003。</p>
              <p>终身学习，躬身入局。</p>
              <p>备注：模型树 高阶课程：点击报名</p>
              <p>
                适合用户：对模型树有一定了解的用户，可以系统学习；我们还会定期办理模型树训练营。
              </p>
            </el-scrollbar></el-tab-pane
          >
          <el-tab-pane label="开始使用" name="test"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="账户注册与使用"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p id="isPasted">注册</p>
              <p>初次打开模型树，根据提示完成注册或登录。</p>
              <p>
                通过网页版注册地址 (https://www.moxingshu.com.cn/login)
                直接访问。推荐通过手机号注册。
              </p>
              <p>
                ℹ️
                &nbsp;手机号为账户基础信息，注册后将无法解除绑定，但可以在设置中修改已绑定的手机号和邮箱，或彻底删除账户。
                手机号暂只支持中国大陆地区号码，注册时如遇到始终无法收到验证码的情况，请通过关于我们页面的用户QQ群947624236
                或者微信siweimoxing003 联系我们。
              </p>
              <p>
                ✅
                账户和用户名的私密性：通过手机号注册可创建账户，用户名称默认为手机用户&amp;手机号。模型树用户名在分享内容时会出现在被分享页，但不会关联到您的其它个人敏感信息。其余情况下用户名仅自己可见。
              </p>
              <p>#快捷登录（开发中）</p>
              <p>
                你可以通过微信、 快捷登录，登录后将在后台自动注册 模型树
                账户。推荐微信用户使用微信快捷登录，绑定手机号，便于在其他场景可选便捷的登录方式。
              </p>
              <p>#账户管理</p>
              <p>模型树账户提供删除账户等操作，如需删除请联系客服人员。</p>
              <p>
                ❗️如执行删除账户操作将彻底删除账户相关所有内容，无法恢复，请谨慎进行。
              </p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="删除账户信息"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p id="isPasted">#账户管理</p>
              <p>模型树账户提供删除账户等操作，如需删除请联系客服人员。</p>
              <p>
                ❗️如执行删除账户操作将彻底删除账户相关所有内容，无法恢复，请谨慎进行。
              </p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="🥝阅读并处理"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="阅读和浏览"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p id="isPasted">
                模型树阅读器用来展示所有你收藏的内容详情，并提供自定义阅读样式和标注的能力，帮助你更好地阅读并记录想法。
              </p>
              <p>#自定义字号</p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/c758e38116c645b782a294fd6cb0b1c8.png"
                  style="
                    width: 701px;
                    display: block;
                    vertical-align: top;
                    margin: 5px auto;
                    text-align: center;
                  "
                /><br />
              </p>
              <p>#工具栏自定义</p>
              <p><strong>1、编辑器设置</strong></p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/0d7ca9e0d71347e095786c89f15b1c85.png"
                  style="
                    width: 702px;
                    display: block;
                    vertical-align: top;
                    margin: 5px auto;
                    text-align: center;
                  "
                />
              </p>
              <p>
                <em>1.1 整体工具栏可单选</em> ☑️跟随光标、☑️固定顶部、☑️固定底部
              </p>
              <p id="isPasted">
                <em>1.2 编辑器黏贴模式可单选</em> ☑️清除格式、☑️不清楚格式
              </p>
              <p>
                <em>1.3 自定义按钮设置</em>
                <span style="color: rgb(222, 78, 78); font-weight: bold"
                  >重要内容的</span
                >字体颜色和底色
              </p>
              <p>
                <em>1.4 工具栏按钮可勾选</em>
                粗体、非常重要、重要内容、段落格式、斜体、删除线、字体大小、字体颜色、背景颜色、清除格式、出处、插入图片、插入数学公式、行间距、段间距、有序列表、无序列表、分割线、撤销、重做、剪切。
              </p>
              <p><strong>2、导图设置</strong></p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/d39b3c8abfa74af7a22a299e560eb454.png"
                  style="
                    width: 552px;
                    display: block;
                    vertical-align: top;
                    margin: 5px auto;
                    text-align: center;
                  "
                /><br />
              </p>
              <p>3、菜单设置</p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/c6a6b4d45dc44030bab29d2e4b5db4b7.png"
                  style="
                    width: 560px;
                    display: block;
                    vertical-align: top;
                    margin: 5px auto;
                    text-align: center;
                  "
                /><br />
              </p>
              <p>4、出处设置</p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/00b34311e44a4eca89067a4b607b3cc0.png"
                  style="
                    width: 552px;
                    display: block;
                    vertical-align: top;
                    margin: 5px auto;
                    text-align: center;
                  "
                /><br />
              </p>
              <p>5、主题设置</p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/259ca8929b83402992c0027dc06d135d.png"
                  style="
                    width: 516px;
                    display: block;
                    vertical-align: top;
                    margin: 5px auto;
                    text-align: center;
                  "
                /><br />
              </p>
              <p>以及其他功能，等待你的探索😯</p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="思维导图"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p>思维导图</p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/5735ad026fad4a0db5e365907ad06b3b.png"
                  style="
                    width: 532px;
                    display: block;
                    vertical-align: top;
                    margin: 5px auto;
                    text-align: center;
                  "
                />1、可以便捷的在模型树标题处点击，进入思维导图模式
              </p>
              <p>2、点击标题，右侧可以方便查看、编辑文本</p>
              <p>3、下载、放大、全局等按钮，快捷使用功能。</p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="📖整理与管理"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="提炼模型"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p>文本编辑，模型提炼等</p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="🔍快捷搜索"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="快速查找"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p>点击搜索框并填写关键词激活搜索功能。</p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/8dd883b2d786427f95614dba36334ec0.png"
                />
              </p>
              <p>点击搜索结果后，进入对应文章。</p>
              <p>点击搜索，搜索当前文章内容。</p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="分享与导出"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="思维导图分享"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p>在思维导图页面，点击快捷方式下载。</p>
              <p>即可下载思维导图图片。</p></el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="PDF导出"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              >更多导出新功能，敬请期待😄</el-scrollbar
            ></el-tab-pane
          >
          <el-tab-pane label="思维导图"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="思维导图"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p id="isPasted">
                思维导图页面，除了鼠标点击外，更多快捷方式正在开发中
              </p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/25b95406259a4fccbe90bab0ae0f7a8b.png"
                />
              </p>
              <p><br /></p></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="其他功能"
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="重要果实"
            ><el-scrollbar style="height: calc(100vh - 100px)"
              ><p>
                为方便用户定义【重要果实】，在当前模型树标题右边，右键后快捷选择「🍎切换为“重要果实”」
              </p>
              <p>
                <img
                  src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/4b43b24cc544450eb3869c062d8b8948.png"
                /></p></el-scrollbar
          ></el-tab-pane>
          <el-tab-pane label="关于我们">
            <el-scrollbar style="height: calc(100vh - 100px)">
              <div
                style="
                  font-weight: 900;
                  font-size: 48px;
                  line-height: 67px;
                  text-align: center;
                "
              >
                关于我们
              </div>
              <div
                style="
                  margin-top: 30px;
                  padding: 40px;
                  background: #f7f7f7;
                  border-radius: 8px;
                "
              >
                <div
                  style="font-size: 40px; line-height: 56px; text-align: center"
                >
                  我们是谁
                </div>
                <div
                  style="
                    margin-top: 25px;
                    font-size: 18px;
                    line-height: 40px;
                    text-align: justify;
                    text-indent: 2em;
                  "
                >
                  <p>
                    我们是逑知（深圳）教育科技有限公司，成立于2021年8月，致力于研究底层学习规律，是领域知名的学习方法咨询服务机构。
                  </p>
                  <p>
                    我们为学校提供学习力提升整体解决方案，帮企业打造学习型组织，帮个人学会科学地学习。
                  </p>
                  <p>
                    公司创始团队经过长期的研究探索，研发出以“模型树”为核心的学习方法体系，旗下拥有“思维有了模型”、“学习有了方法”、“逑知学研院”等多个领域知名的自媒体品牌，受益学员数万名，一路走来，感谢朋友们的信任、陪伴和支持。
                  </p>
                  <p>
                    模型树是逑知教育旗下一款专业的学习工具软件，其主要定位是解决爱学习者在工作和学习中的方法和效率问题，梳理学习思路，协作学习，进行内容创作和任务管理等。
                  </p>
                </div>
              </div>
              <div
                style="
                  margin-top: 100px;
                  padding: 40px;
                  background: #f7f7f7;
                  border-radius: 8px;
                  text-align: center;
                "
              >
                <div style="font-size: 40px; line-height: 56px">我们的使命</div>
                <div
                  style="margin-top: 25px; font-size: 18px; line-height: 40px"
                >
                  帮助1亿国人科学学习，助力中国引领人类进入知识革命时代。
                </div>
              </div>
              <div
                style="
                  margin-top: 70px;
                  padding: 40px;
                  background: #f7f7f7;
                  border-radius: 8px;
                "
              >
                <div
                  style="font-size: 40px; line-height: 56px; text-align: center"
                >
                  联系我们
                </div>
                <div
                  style="margin-top: 15px; font-size: 20px; line-height: 42px"
                >
                  公司地址：广东省深圳市南山区粤海街道高新区社区高新南七道018号高新工业村R3-B栋01层211<br />
                  用户QQ群：947624236<br />
                  联系微信：siweimoxing003
                </div>
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label=""
            ><el-scrollbar style="height: calc(100vh - 100px)"></el-scrollbar
          ></el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logoUrl: "/pc/logo-default.png",
    };
  },
  watch: {
    '$root.config.theme': {
      handler(val, old) {
        if (val !== old) {
          this.changeTheme();
        }
      }
    }
  },
  mounted() {
    this.changeTheme();
  },
  methods: {
    changeTheme() {
      if (this.$root.config.theme === "dark") {
        this.logoUrl = "/pc/logo-dark.png";
        document
            .querySelector("body")
            .setAttribute("style", "background:#101218");
      } else {
        document.querySelector("body").removeAttribute("style");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}
.user-container {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 30px;
}
.user-container-head {
  width: 123px;
  height: 40px;
}
.tabs {
  height: calc(100vh - 100px);
  :deep .el-tabs__header.is-left {
    width: 200px;
  }
}
:deep .el-tabs__content {
  max-width: 750px;
  margin: 0 auto;
}
p img {
  max-width: 750px;
}
</style>
